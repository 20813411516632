var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"import-dialog"},[_c('el-dialog',{attrs:{"center":"","title":"导入设置","visible":_vm.dialogVisible,"width":"429px","before-close":_vm.closeDialog},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('el-form',{ref:"ruleForm",attrs:{"model":_vm.ruleForm,"rules":_vm.rules,"label-width":"110px"}},[(_vm.clueOrDialog != 2)?_c('el-form-item',{attrs:{"label":"超时时间设置","prop":"expireTime"}},[_c('el-date-picker',{staticStyle:{"width":"250px"},attrs:{"editable":false,"type":"datetime","size":"medium","placeholder":"选择超时日期","value-format":"yyyy-MM-dd HH:mm:ss","picker-options":_vm.pickersOptions},on:{"change":_vm.timeChange},model:{value:(_vm.ruleForm.expireTime),callback:function ($$v) {_vm.$set(_vm.ruleForm, "expireTime", $$v)},expression:"ruleForm.expireTime"}})],1):_vm._e(),_c('el-form-item',{attrs:{"label":"导入位置","prop":"target"}},[_c('el-select',{staticStyle:{"width":"250px"},attrs:{"size":"medium","placeholder":"请选择活动区域"},on:{"change":_vm.changeTarget},model:{value:(_vm.ruleForm.target),callback:function ($$v) {_vm.$set(_vm.ruleForm, "target", $$v)},expression:"ruleForm.target"}},_vm._l((_vm.targetArr),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('el-form-item',{attrs:{"prop":"departmentId","rules":_vm.ruleForm.target != 4
          ? [{ type: 'array', message: '请选择部门', trigger: ['change'] }]
          : [{ type: 'number', message: '请选择公海', trigger: ['change'] }]}},[(_vm.ruleForm.target == 4)?_c('el-select',{staticStyle:{"width":"250px"},attrs:{"clearable":"","size":"medium","placeholder":"请选择公海"},model:{value:(_vm.ruleForm.departmentId),callback:function ($$v) {_vm.$set(_vm.ruleForm, "departmentId", $$v)},expression:"ruleForm.departmentId"}},_vm._l((_vm.departmentSea),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1):_c('el-cascader',{key:_vm.isResouceShow,ref:"cascaderHandle",staticStyle:{"width":"250px"},attrs:{"size":"medium","options":_vm.departmentsArr,"props":{
          expandTrigger: 'hover',
          checkStrictly: true,
          label: 'name',
          value: 'id'
        },"placeholder":"选择部门"},on:{"change":_vm.selDepartVal},model:{value:(_vm.ruleForm.departmentId),callback:function ($$v) {_vm.$set(_vm.ruleForm, "departmentId", $$v)},expression:"ruleForm.departmentId"}})],1),(_vm.ruleForm.target == 1)?_c('el-form-item',{attrs:{"prop":"staffId"}},[_c('el-select',{staticStyle:{"width":"250px"},attrs:{"placeholder":"请选择员工","clearable":"","size":"medium"},model:{value:(_vm.ruleForm.staffId),callback:function ($$v) {_vm.$set(_vm.ruleForm, "staffId", $$v)},expression:"ruleForm.staffId"}},_vm._l((_vm.staffOption),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],1):_vm._e(),(_vm.clueOrDialog != 2&&_vm.ruleForm.target != 4)?_c('el-form-item',{attrs:{"label":"是否计入条数","prop":"count"}},[_c('el-radio-group',{model:{value:(_vm.ruleForm.count),callback:function ($$v) {_vm.$set(_vm.ruleForm, "count", $$v)},expression:"ruleForm.count"}},[_c('el-radio',{attrs:{"label":1}},[_vm._v("是")]),_c('el-radio',{attrs:{"label":0}},[_vm._v("否")])],1)],1):_vm._e()],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submitDialog}},[_vm._v("确 定")]),_c('el-button',{on:{"click":_vm.closeDialog}},[_vm._v("取 消")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }