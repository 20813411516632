<template>
    <div class="import-dialog">
        <el-dialog
            center
            title="导入设置"
            :visible.sync="dialogVisible"
            width="429px"
            :before-close="closeDialog"
        >
            <!-- 表单区域 -->
            <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="110px">
                <!-- :picker-options="{
              disabledDate: (time) => {
                return time.getTime() < Date.now() - 3600 * 1000 * 24 - 8;
              },
              selectableRange: startTimeRange,
                }"-->
                <!-- 超时时间 -->
                <!-- {{startTimeRange}} -->
                <el-form-item v-if="clueOrDialog != 2" label="超时时间设置" prop="expireTime">
                    <el-date-picker
                        v-model="ruleForm.expireTime"
                        :editable="false"
                        style="width:250px"
                        type="datetime"
                        size="medium"
                        placeholder="选择超时日期"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        :picker-options="pickersOptions"
                        @change="timeChange"
                    />
                </el-form-item>
                <!-- 导入位置-->
                <el-form-item label="导入位置" prop="target">
                    <el-select
                        v-model="ruleForm.target"
                        style="width:250px"
                        size="medium"
                        placeholder="请选择活动区域"
                        @change="changeTarget"
                    >
                        <el-option
                            v-for="(item, index) in targetArr"
                            :key="index"
                            :label="item.label"
                            :value="item.value"
                        />
                    </el-select>
                </el-form-item>
                <!-- 选择部门 -->
                <el-form-item
                    prop="departmentId"
                    :rules="
            ruleForm.target != 4
              ? [{ type: 'array', message: '请选择部门', trigger: ['change'] }]
              : [{ type: 'number', message: '请选择公海', trigger: ['change'] }]
          "
                >
                    <el-select
                        v-if="ruleForm.target == 4"
                        v-model="ruleForm.departmentId"
                        clearable
                        style="width:250px"
                        size="medium"
                        placeholder="请选择公海"
                    >
                        <el-option
                            v-for="item in departmentSea"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        />
                    </el-select>
                    <el-cascader
                        v-else
                        :key="isResouceShow"
                        ref="cascaderHandle"
                        v-model="ruleForm.departmentId"
                        style="width:250px"
                        size="medium"
                        :options="departmentsArr"
                        :props="{
              expandTrigger: 'hover',
              checkStrictly: true,
              label: 'name',
              value: 'id'
            }"
                        placeholder="选择部门"
                        @change="selDepartVal"
                    />
                </el-form-item>
                <!-- 选择员工 -->
                <el-form-item v-if="ruleForm.target == 1" prop="staffId">
                    <el-select
                        v-model="ruleForm.staffId"
                        placeholder="请选择员工"
                        clearable
                        style="width:250px"
                        size="medium"
                    >
                        <el-option
                            v-for="item in staffOption"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        />
                    </el-select>
                </el-form-item>
                <!-- 公司池公司海 -->

                <!-- 是否计入条数  -->
                <el-form-item
                    v-if="clueOrDialog != 2&&ruleForm.target != 4"
                    label="是否计入条数"
                    prop="count"
                >
                    <el-radio-group v-model="ruleForm.count">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitDialog">确 定</el-button>
                <el-button @click="closeDialog">取 消</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { departments, staff, companySea } from '@/api/public_content.js';
export default {
    data() {
        return {
            startTimeRange: '',
            dialogVisible: false,
            // expireTime: "",
            ruleForm: {
                target: 1,
                departmentId: '',
                staffId: '',
                expireTime: '',
                count: 1,
            }, //表单数据
            targetArr: [
                { value: 1, label: '员工个人' },
                { value: 2, label: '本部门员工' },
                { value: 3, label: '本部门及下级部门员工' },
                { value: 4, label: '公海' },
            ],
            rules: {
                expireTime: [
                    {
                        required: true,
                        message: '请选择超时日期',
                        trigger: 'change',
                    },
                ],
                target: [
                    {
                        required: true,
                        message: '请选择导入位置',
                        trigger: 'change',
                    },
                ],
                count: [
                    {
                        required: true,
                        message: '请选择是否计入条数',
                        trigger: 'change',
                    },
                ],
                staffId: [
                    {
                        type: 'number',
                        message: '请选择员工',
                        trigger: 'change',
                    },
                ],
            },
            departmentSea: [], //公司池公司海
            departmentsArr: [],
            staffOption: [],
            isResouceShow: 0,
            pickersOptions: {
                disabledDate: (time) => {
                    return time.getTime() < Date.now() - 3600 * 1000 * 24;
                },
                selectableRange: '00:00:00-23:59:59',
            },
            clueOrDialog: '',
        };
    },
    watch: {
        // "ruleForm.expireTime": {
        //   deep: true,
        //   handler(newValue, oldValue) {
        //     console.log(newValue);
        //     if (newValue) {
        //       let nowDate = this.$moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        //       // 当前时间按空格分成数组
        //       let dt = nowDate.split(" ");
        //       let st = "";
        //       // 监听时间
        //       if (newValue.split(" ")[0] == dt[0]) {
        //         // 是今天,选择 的时间开始为此刻的时分秒
        //         st = dt[1];
        //         console.log(st);
        //         if (newValue.split(" ")[1] < st) {
        //           this.$message.warning("请选择超时时间大于此刻的时间");
        //         }
        //       } else {
        //         // 明天及以后从0时开始
        //         st = "00:00:00";
        //       }
        //       this.$nextTick(() => {
        //         this.pickersOptions.selectableRange = st + " - 23:59:59";
        //       });
        //       // this.startTimeRange = st + " - 23:59:59";
        //     }
        //   },
        // },
    },
    methods: {
        init(val) {
            this.clueOrDialog = val;
            this.dialogVisible = true;
            this.getDepartment();
            companySea().then((res) => {
                let data = res.data.list;
                // console.log(data);
                this.departmentSea = data;
            });
        },
        getDepartment() {
            departments().then((res) => {
                if (res.code == 0) {
                    this.departmentsArr = this.common.getTreeData(
                        res.data.list
                    );
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        selDepartVal() {
            this.$refs.cascaderHandle.dropDownVisible = false;
            staff({
                departmentId:
                    this.ruleForm.departmentId[
                        this.ruleForm.departmentId.length - 1
                    ],
            }).then((res) => {
                if (res.code == 0) {
                    this.staffOption = res.data.list;
                    this.ruleForm.staffId = '';
                    console.log(this.staffOption, 'this.staffOption');
                } else {
                    this.$message.error(res.error);
                }
            });
        },
        closeDialog() {
            this.expireTime = '';
            this.dialogVisible = false;
            ++this.isResouceShow;
            // this.departVal = [];
            // this.staffId = "";
            this.departmentsArr = [];
            this.staffOption = [];
            this.$refs.ruleForm.resetFields();
        },
        dateFormat(dateData) {
            // console.log();
            let date = new Date(dateData);
            let y = date.getFullYear();
            let m = date.getMonth() + 1;
            m = m < 10 ? '0' + m : m;
            let d = date.getDate();
            d = d < 10 ? '0' + d : d;
            const time = y + '-' + m + '-' + d;
            return time;
        },
        changeTarget() {
            this.ruleForm.departmentId = '';
            this.ruleForm.staffId = '';
        },
        submitDialog() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    let nowDate = this.$moment(new Date()).format(
                        'YYYY-MM-DD HH:mm:ss'
                    );
                    // 当前时间按空格分成数组
                    let nowTime = nowDate.split(' ');
                    let selectTime = this.ruleForm.expireTime.split(' ');
                    if (
                        selectTime[0] == nowTime[0] &&
                        nowTime[1] >= selectTime[1]
                    ) {
                        this.$message.warning('请选择超时时间大于此刻的时间');
                        // this.ruleForm.expireTime = "";
                    } else {
                        if (typeof this.ruleForm.departmentId === 'object') {
                            this.ruleForm.departmentId =
                                this.ruleForm.departmentId[
                                    this.ruleForm.departmentId.length - 1
                                ];
                        }

                        let timestampLogin = new Date(
                            this.ruleForm.expireTime
                        ).getTime();
                        let data = {
                            target: this.ruleForm.target,
                            departmentId: this.ruleForm.departmentId,
                            staffId: this.ruleForm.staffId,
                            expireTime: timestampLogin / 1000,
                            count: this.ruleForm.count,
                        };
                        this.$emit('submitForm', data);
                        this.dialogVisible = false;
                        this.closeDialog();
                    }
                } else {
                    return false;
                }
            });
        },
        timeChange() {
            let nowDate = this.$moment(new Date()).format(
                'YYYY-MM-DD HH:mm:ss'
            );
            // 当前时间按空格分成数组
            let nowTime = nowDate.split(' ');
            let selectTime = this.ruleForm.expireTime.split(' ');
            if (selectTime[0] == nowTime[0] && nowTime[1] > selectTime[1]) {
                this.$message.warning('请选择超时时间大于此刻的时间');
                // this.ruleForm.expireTime = "";
            }
        },
    },
};
</script>

<style lang="less" scoped>
.import-dialog {
}
</style>
